import React from "react"
import PropTypes from "prop-types"
import { graphql } from "gatsby"
import { useTranslation } from "react-i18next"
import { useAgroPageHeadingSection } from "../hooks/AgroPage/useAgroPageHeadingSection"
import { useAgroPageMiddleSection } from "../hooks/AgroPage/useAgroPageMiddleSection"
import { useAgroPageBottomSection } from "../hooks/AgroPage/useAgroPageBottomSection"
import Layout from "../components/Layout/Layout"
import TemplatePageHeaderSection from "../components/TemplatePageHeaderSection/TemplatePageHeaderSection"
import TemplatePageContentSection from "../components/TemplatePageContentSection/TemplatePageContentSection"
import TemplatePageConfigSection from "../components/TemplatePageConfigSection/TemplatePageConfigSection"
import Footer from "../components/Footer/Footer"
import SeoPage from "../components/seo-page"

const AgroPage = ({ location }) => {
  const { i18n } = useTranslation()
  const { language } = i18n
  const {
    title: headingTitle,
    subtitle,
    buttonLink: headingButton,
    headingSectionImage,
  } = useAgroPageHeadingSection()
  const {
    middleSectionServices,
    middleSectionLinks: { links },
    middleSectionContent: { ...content },
  } = useAgroPageMiddleSection()
  const {
    title: bottomTitle,
    programText,
    notes,
    backgroundImage,
    buttonLink: bottomButton,
    icon,
  } = useAgroPageBottomSection()
  const seoFields = {
    title: {
      en: "Agro solar panels - Ensys",
      ro: "Panouri solare agro - Ensys",
    },
    keyword: {
      en: "Agro solar panels - Ensys",
      ro: "Panouri solare agro",
    },
    image: {
      en: "https://wp.ensys.com.ro/wp-content/uploads/2023/04/Agro2-scaled.jpg",
      ro: "https://wp.ensys.com.ro/wp-content/uploads/2023/04/Agro2-scaled.jpg",
    },
    slug: {
      en: "Panouri solare pentru domeniul Agro",
      ro: "Panouri solare pentru domeniul Agro",
    },
  }
  function getSeoFields(field) {
    return seoFields[field][language] ?? seoFields[field].ro // return ro fields if current language does not have them
  }
  return (
    <Layout>
      <SeoPage
        title={getSeoFields("title")}
        keyword={getSeoFields("keyword")}
        image={getSeoFields("image")}
        slug={getSeoFields("slug")}
      />
      <TemplatePageHeaderSection
        title={headingTitle}
        subtitle={subtitle}
        buttonLink={headingButton}
        image={headingSectionImage}
        location={location}
      />
      <TemplatePageContentSection
        links={links}
        content={Object.values(content)}
        services={middleSectionServices}
      />
      <TemplatePageConfigSection
        title={bottomTitle}
        programText={programText}
        notes={notes}
        image={backgroundImage}
        icon={icon}
        buttonLink={bottomButton}
      />
      <Footer />
    </Layout>
  )
}

AgroPage.propTypes = {
  location: PropTypes.shape({}).isRequired,
}
export const query = graphql`
  query ($language: String!) {
    locales: allLocale(filter: { language: { eq: $language } }) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }
  }
`
export default AgroPage
